import React from "react"

function SvgComponent(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="prefix__my_trips"
			width="1em"
			height="1em"
			viewBox="0 0 39.5 33.632"
			{...props}
		>
			<defs>
				<style>
					{
						".prefix__cls-3,.prefix__cls-4{fill:#f5c2a4}.prefix__cls-3{opacity:.72}.prefix__cls-5{fill:#78232f}"
					}
				</style>
			</defs>
			<path
				id="prefix__Path_1165"
				fill="#ad3032"
				d="M730.732 275.221a3.509 3.509 0 00-.714.073 2.816 2.816 0 00-.369.099c-.037.013-.071.022-.1.035-.016.006-.03.01-.045.018-.039.013-.078.029-.116.045s-.06.025-.089.039-.087.039-.129.061-.112.057-.167.089a3.237 3.237 0 00-.326.21c-.047.033-.092.068-.137.1s-.074.06-.109.09l-.1.089a.3.3 0 01-.023.023c-.032.03-.063.061-.091.091s-.068.071-.1.108a3.524 3.524 0 00-.282.359 1.441 1.441 0 00-.066.1.156.156 0 00-.012.019l-.062.1c-.01.015-.017.031-.026.045s-.034.06-.05.091l-.016.03-.051.1a3.433 3.433 0 00-.214.581l-.034.132v.02l-.025.121c-.008.041-.015.083-.02.125s-.015.1-.019.152a.034.034 0 000 .016c-.005.049-.01.1-.013.15v.288c0 .029 0 .071.009.109s0 .051.009.077.009.067.015.1a5.062 5.062 0 00.122.532l.065.218.037.115c.025.073.049.148.077.225.049.139.1.282.16.43s.122.308.189.466a30.226 30.226 0 00.429.97l.131.276c.121.251.244.506.37.759a64.582 64.582 0 001.907 3.509c.131-.224.275-.474.427-.745.024-.037.045-.077.067-.118.055-.1.11-.2.169-.3l.116-.209c.04-.071.087-.159.131-.24.262-.478.535-.993.8-1.515.024-.047.05-.095.073-.142.458-.9.892-1.827 1.212-2.639.018-.042.033-.086.049-.128l.06-.159.074-.2c.071-.2.134-.394.184-.574.016-.058.032-.115.045-.17l.039-.166.029-.139a.226.226 0 01.006-.034c.007-.045.014-.088.019-.131l.012-.1V278.749a3.526 3.526 0 00-3.527-3.528zm0 5.527a2 2 0 112-2 2.007 2.007 0 01-2 2z"
				transform="translate(-696.854 -268.776)"
			/>
			<path
				id="prefix__Path_1166"
				fill="#e9973e"
				d="M668.151 266.32v-.058c0-.057-.008-.115-.012-.172v-.023l-.02-.183v-.026c-.009-.064-.019-.127-.031-.189s-.02-.106-.032-.157l-.019-.08a3.61 3.61 0 00-.166-.542 2.661 2.661 0 00-.087-.214 4.461 4.461 0 00-.343-.643.329.329 0 00-.019-.029c-.034-.052-.068-.1-.1-.153a.045.045 0 00-.01-.013 2.2 2.2 0 00-.108-.146 4.073 4.073 0 00-.265-.311c-.045-.048-.09-.094-.136-.139s-.089-.088-.135-.13l-.008-.006c-.049-.045-.1-.087-.149-.129l-.01-.009c-.042-.035-.086-.07-.131-.1s-.114-.087-.173-.126-.093-.064-.14-.094l-.038-.024a2.877 2.877 0 00-.151-.091h-.006c-.057-.034-.115-.064-.173-.094l-.016-.009-.163-.08q-.07-.033-.139-.061c-.025-.012-.051-.022-.077-.032l-.022-.009a3.692 3.692 0 00-.517-.169c-.07-.017-.131-.033-.2-.046s-.132-.028-.2-.04-.125-.02-.188-.027l-.188-.02c-.064-.006-.126-.009-.19-.011s-.129 0-.193 0a4.58 4.58 0 00-4.575 4.574 2.535 2.535 0 00.023.337c0 .04.01.081.017.124s.013.084.022.128.016.089.026.134.019.091.031.138a8.082 8.082 0 00.248.845c.052.162.112.327.176.5.038.1.077.205.119.31l.073.185c.472 1.178 1.118 2.5 1.756 3.732l.019.037c.045.087.09.174.137.26.082.154.161.307.243.456.055.106.112.211.167.314.251.465.494.9.714 1.3l.081.145c.246.433.461.807.628 1.095l.084.144c0 .009.009.016.013.024l.012-.021c.173-.3.413-.709.692-1.2.034-.06.068-.121.1-.182s.055-.1.084-.149a.193.193 0 01.021-.035l.216-.393c.009-.014.016-.029.023-.042l.182-.331c.111-.2.223-.408.336-.619.026-.049.052-.1.08-.149q.159-.3.321-.611l.124-.24c.228-.442.456-.894.674-1.346.052-.106.1-.214.154-.32.078-.166.156-.33.231-.494.047-.1.092-.2.137-.3a28.994 28.994 0 00.55-1.307c.022-.059.042-.106.061-.158.068-.178.131-.352.188-.52.019-.055.038-.109.053-.163s.035-.106.051-.159c.05-.156.092-.307.129-.452l.035-.142c.012-.048.022-.093.03-.138s.019-.09.027-.134.016-.086.022-.128.013-.083.017-.124c.01-.08.017-.157.02-.23v-.108c-.013-.084-.014-.142-.017-.202zm-4.57 2.642a2.464 2.464 0 112.464-2.464 2.466 2.466 0 01-2.464 2.464z"
				transform="translate(-658.352 -261.269)"
			/>
			<path
				id="prefix__Path_1167"
				d="M732.142 292.56c-.429-.831-1.684-.1-1.255.734l1.4 2.708c.429.831 1.684.1 1.255-.734z"
				className="prefix__cls-3"
				transform="translate(-698.88 -278.358)"
			/>
			<path
				id="prefix__Path_1168"
				d="M729.517 276.4c.869-.34.178-1.62-.685-1.282a3.855 3.855 0 00-2.2 4.931c.323.863 1.744.544 1.415-.332a2.467 2.467 0 011.47-3.317z"
				className="prefix__cls-3"
				transform="translate(-696.392 -268.688)"
			/>
			<path
				id="prefix__Path_1169"
				d="M664.746 287.019l1.547 2.836c.448.82 1.651 0 1.206-.812l-1.547-2.836c-.452-.82-1.652-.007-1.206.812z"
				className="prefix__cls-4"
				transform="translate(-661.537 -274.785)"
			/>
			<path
				id="prefix__Path_1170"
				d="M660.352 267.671a3.362 3.362 0 013.233-4.725c.936.03.935-1.424 0-1.454a4.808 4.808 0 00-4.634 6.565c.349.857 1.749.482 1.401-.386z"
				className="prefix__cls-4"
				transform="translate(-658.124 -261.024)"
			/>
			<path
				id="prefix__Path_1171"
				d="M673.909 310.949a.327.327 0 00.193.163l9.782 3.095a.332.332 0 00.1.015.327.327 0 00.25-.115l3.345-3.949a.327.327 0 00-.5-.423l-3.2 3.783-9.2-2.912 1.658-1.957 1.592-1.734 1.479-1.746a.327.327 0 00-.5-.423l-1.47 1.737-1.592 1.734-1.615 1.907-4.3-8.415 3.56-4.2a.327.327 0 00-.5-.423l-3.559 4.2-9.106-2.881 1.454-1.718a.327.327 0 10-.5-.423l-1.772 2.093a.328.328 0 00.151.523l9.652 3.054z"
				className="prefix__cls-5"
				transform="translate(-658.589 -280.589)"
			/>
			<path
				id="prefix__Path_1172"
				d="M737.277 298.24a.328.328 0 00-.216-.221l-1.906-.6a.327.327 0 00-.2.624l1.438.454-7.451 8.8a.327.327 0 00.5.423l7.769-9.174a.327.327 0 00.066-.306z"
				className="prefix__cls-5"
				transform="translate(-697.79 -281.297)"
			/>
			<path
				id="prefix__Path_1173"
				d="M701.978 276.475a.327.327 0 00.291-.476l-4.661-9.129a.323.323 0 00-.084-.11.327.327 0 00-.125-.065l-9.769-3.091a.327.327 0 00-.348.1l-2.983 3.522a.327.327 0 00.5.423l2.842-3.356 9.105 2.881-4.892 5.777a.327.327 0 10.5.422l4.892-5.777 4.441 8.7a.327.327 0 00.291.179z"
				className="prefix__cls-5"
				transform="translate(-672.587 -262.209)"
			/>
			<path
				id="prefix__Path_1174"
				d="M716 298.63a.327.327 0 00.25-.116l2.5-2.95a.327.327 0 10-.5-.422l-2.5 2.95a.327.327 0 00.249.539z"
				className="prefix__cls-5"
				transform="translate(-690.341 -279.956)"
			/>
			<path
				id="prefix__Path_1175"
				d="M712.333 309.7a3.8 3.8 0 01-1.591-.092.327.327 0 00-.174.63 4.422 4.422 0 001.865.109.327.327 0 00-.1-.647z"
				className="prefix__cls-5"
				transform="translate(-687.324 -288.181)"
			/>
			<path
				id="prefix__Path_1176"
				d="M730.018 304.817a.328.328 0 00-.409-.216l-1.666.516a.327.327 0 10.194.625l1.666-.516a.327.327 0 00.215-.409z"
				className="prefix__cls-5"
				transform="translate(-697.138 -285.354)"
			/>
			<path
				id="prefix__Path_1177"
				d="M704.954 305.733a.327.327 0 00.469-.456 3.773 3.773 0 01-.724-1.056l-.145-.315a.327.327 0 10-.594.274l.145.315a4.424 4.424 0 00.849 1.238z"
				className="prefix__cls-5"
				transform="translate(-683.712 -284.862)"
			/>
			<path
				id="prefix__Path_1178"
				d="M700.82 297.632a.327.327 0 00.594-.273l-.378-.819a4.468 4.468 0 00-.462-.782.327.327 0 00-.526.389 3.816 3.816 0 01.394.667z"
				className="prefix__cls-5"
				transform="translate(-681.485 -280.295)"
			/>
			<path
				id="prefix__Path_1179"
				d="M721.41 307.485a.328.328 0 00-.409-.216l-1.667.516a.327.327 0 10.194.625l1.666-.516a.327.327 0 00.216-.409z"
				className="prefix__cls-5"
				transform="translate(-692.278 -286.86)"
			/>
			<path
				id="prefix__Path_1180"
				d="M675 296.014a.327.327 0 00.079-.01l1.693-.421a.327.327 0 10-.158-.635l-1.693.421a.327.327 0 00.078.645z"
				className="prefix__cls-5"
				transform="translate(-667.193 -279.907)"
			/>
			<path
				id="prefix__Path_1181"
				d="M692.6 292.446a3.7 3.7 0 011.5.307.327.327 0 00.257-.6 4.41 4.41 0 00-1.747-.359h-.086a.327.327 0 10.012.654z"
				className="prefix__cls-5"
				transform="translate(-677.092 -278.131)"
			/>
			<path
				id="prefix__Path_1182"
				d="M683.425 293.593a.327.327 0 00.317.248.331.331 0 00.079-.01l1.693-.421a.327.327 0 10-.158-.635l-1.693.421a.327.327 0 00-.238.397z"
				className="prefix__cls-5"
				transform="translate(-672.131 -278.681)"
			/>
			<path
				id="prefix__Path_1183"
				d="M662.732 276.82a.327.327 0 00.28-.159c.2-.335 4.949-8.246 4.949-11.012a5.229 5.229 0 00-10.458 0c0 2.766 4.747 10.676 4.949 11.012a.327.327 0 00.28.159zm0-15.745a4.58 4.58 0 014.575 4.575c0 2.219-3.541 8.433-4.575 10.2-1.034-1.768-4.574-7.981-4.574-10.2a4.58 4.58 0 014.574-4.575z"
				className="prefix__cls-5"
				transform="translate(-657.503 -260.421)"
			/>
			<path
				id="prefix__Path_1184"
				d="M668.78 269.233a2.464 2.464 0 10-2.464 2.464 2.467 2.467 0 002.464-2.464zm-4.274 0a1.81 1.81 0 111.81 1.81 1.812 1.812 0 01-1.81-1.81z"
				className="prefix__cls-5"
				transform="translate(-661.087 -264.005)"
			/>
			<path
				id="prefix__Path_1185"
				d="M729.883 286.737a.327.327 0 00.28-.159c.159-.264 3.9-6.491 3.9-8.682a4.176 4.176 0 10-8.352 0c0 2.191 3.737 8.419 3.9 8.682a.327.327 0 00.272.159zm0-12.363a3.525 3.525 0 013.517 3.526c0 1.537-2.328 5.832-3.522 7.872-1.194-2.04-3.522-6.335-3.522-7.872a3.525 3.525 0 013.527-3.526z"
				className="prefix__cls-5"
				transform="translate(-696.006 -267.929)"
			/>
			<path
				id="prefix__Path_1186"
				d="M734.7 280.71a2.005 2.005 0 10-2.005 2 2.007 2.007 0 002.005-2zm-3.356 0a1.351 1.351 0 111.351 1.351 1.353 1.353 0 01-1.349-1.351z"
				className="prefix__cls-5"
				transform="translate(-698.82 -270.743)"
			/>
		</svg>
	)
}

export default SvgComponent
