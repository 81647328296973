import React from "react";
import styles from "./attractions-points-of-interest.scss";
import { Select, Table } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import { Button, Input } from "../../core";
import update from "immutability-helper";
import { POINT_OF_INTEREST_TYPES } from "../../../constants/attractions.constants";
import { LOCATION_TYPE } from "../../../constants";
import { TrashIcon, PolylineIcon, MarkerIcon } from "../../../images";

const { Option } = Select;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const ValueCell = ({ record, onChange, error }) => {
  const internalChange = (e) => {
    onChange(e?.target?.value);
  };

  return <Input defaultValue={record} error={error} onBlur={internalChange} />;
};

const TypeSelector = ({ record, row, onChange }) => {
  if (row?.location?.type === LOCATION_TYPE.LineString) return null;

  return (
    <div className={styles.selectType}>
      <Select
        showSearch
        bordered={false}
        value={record}
        style={{ width: "100%" }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onChange={onChange}
      >
        {Object.values(POINT_OF_INTEREST_TYPES).map((v) => (
          <Option value={v} key={v}>
            {v.toLocaleLowerCase()}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const Actions = ({ onDelete }) => {
  return (
    <Button onClick={onDelete}>
      <TrashIcon />
    </Button>
  );
};

const PointType = ({ record }) => {
  return record?.type === LOCATION_TYPE.LineString ? (
    <PolylineIcon className={styles.mapIcon} />
  ) : (
    <MarkerIcon className={styles.mapIcon} />
  );
};

const AttractionsPointsOfInterest = React.memo(
  function AttractionsPointsOfInterest({
    pointsOfInterest,
    onChange,
    name,
    error,
  }) {
    const columns = [
      {
        title: "Sort",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      },
      {
        title: "Type",
        dataIndex: "location",
        editable: true,
        render: (props) => <PointType {...props} />,
      },
      {
        title: "Style",
        dataIndex: "type",
        editable: true,
        render: (props) => <TypeSelector {...props} />,
      },
      {
        title: "Name",
        dataIndex: "name",
        className: "drag-visible",
        editable: true,
        render: (props) => <ValueCell {...props} />,
      },
      {
        title: "Description",
        dataIndex: "description",
        editable: true,
        render: (props) => <ValueCell {...props} />,
      },
      {
        title: "",
        actions: true,
        render: (props) => <Actions {...props} />,
      },
    ];

    const onSortEnd = ({ oldIndex, newIndex }) => {
      console.log("oldIndex, newIndex", oldIndex, newIndex);
      if (oldIndex !== newIndex) {
        const newData = arrayMove(
          [].concat(pointsOfInterest),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        console.log("Sorted items: ", newData);
        onChange(name, newData);
      }
    };

    const DraggableContainer = (props) => (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = pointsOfInterest.findIndex(
        (x) => x.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    };

    if (!pointsOfInterest) {
      return <h4>No point of interest - please create one</h4>;
    }

    // On change value for internal change
    const onChangeValue = (field, index) => (value) => {
      onChange(
        name,
        update(pointsOfInterest, {
          [index]: {
            $merge: {
              [field]: value,
            },
          },
        })
      );
    };

    const onDeleteRow = (index) => () => {
      onChange(
        name,
        update(pointsOfInterest, {
          $splice: [[index, 1]],
        })
      );
    };

    // Map columns with the editable options
    const mappedColumns = columns.map((col) => {
      if (col.editable) {
        return {
          ...col,
          render: (record, row, index) =>
            col.render({
              record,
              row,
              index,
              editable: col.editable,
              dataIndex: col.dataIndex,
              title: col.title,
              error: error && error[index] && error[index][col.dataIndex],
              onChange: onChangeValue(col.dataIndex, index),
            }),
        };
      }

      if (col.actions) {
        return {
          ...col,
          render: (record, row, index) =>
            col.render({
              record,
              row,
              index,
              onDelete: onDeleteRow(index),
            }),
        };
      }

      return col;
    });

    return (
      <div className={styles.root}>
        <Table
          pagination={false}
          dataSource={pointsOfInterest}
          columns={mappedColumns}
          rowKey="id"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>
    );
  }
);

AttractionsPointsOfInterest.defaultProps = {
  pointOfInterest: [],
  onChange: () => {},
  name: "",
  error: {},
};

export { AttractionsPointsOfInterest };
