import { currentEnvironment } from "../utils/environment";

const GOOGLE_DETAILS = {
  development:
    "952207962004-nq1ejpbovu4gc8nml96po9c8ad2ud21n.apps.googleusercontent.com",
  staging:
    "952207962004-nq1ejpbovu4gc8nml96po9c8ad2ud21n.apps.googleusercontent.com",
  production:
    "405979746782-k8m9imtue2u736eucjf9i1vim5aq5gap.apps.googleusercontent.com",
};

export const GOOGLE_CLIENT_ID = GOOGLE_DETAILS[currentEnvironment()];

export const GOOGLE_MAP_API_BASE_URL = "https://maps.googleapis.com";
export const GOOGLE_MAP_API_KEY = "AIzaSyC04sZWi9n9MVBPvd9Ysz2ic75c_-0Gwrg";
