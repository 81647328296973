import update from "immutability-helper";
import {
  AUTH_LOGOUT,
  ATTRACTIONS_FETCH_DATA,
  ATTRACTIONS_FETCH_BY_ID,
  ATTRACTIONS_CREATE_NEW,
} from "../actions";

const initialState = {
  list: null,
  items: {},
  lastUpdated: null,
};

const updateAttractions = (state, { attractions }) =>
  update(state, {
    $merge: {
      list: attractions,
      lastUpdated: Date.now(),
    },
  });

const insertAttractionById = (state, { id, attraction }) =>
  update(state, {
    items: {
      [id]: { $set: attraction },
    },
  });

const createAttraction = (state, { attraction }) =>
  update(state, {
    list: {
      $unshift: [
        {
          id: attraction.id,
          name: attraction.name,
          owner: attraction.owner,
          isPublish: attraction.isPublish,
          primaryPicture: attraction.primaryPicture,
        },
      ],
    },
    items: {
      [attraction.id]: { $set: attraction },
    },
  });

const cleanDate = () => {
  return initialState;
};

const actionsRepo = {
  [ATTRACTIONS_FETCH_DATA]: updateAttractions,
  [ATTRACTIONS_FETCH_BY_ID]: insertAttractionById,
  [ATTRACTIONS_CREATE_NEW]: createAttraction,
  [AUTH_LOGOUT]: cleanDate,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload);
  }

  return state;
}
