import React from "react"

function SvgComponent(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 14.375 13.351"
			{...props} >
			<defs>
				<style>{".prefix__cls-1{fill:#78232f}"}</style>
			</defs>
			<g id="prefix__monument" transform="translate(-480.242 -904.851)">
				<path
					id="prefix__Path_3940"
					d="M503.02 945.52h1.08v.831h-11.386v-.786l.823-.068v-3.543l-.823-.058v-.8h11.4v.8h-1.094zm-7.789-3.564v3.53h1.1v-3.53zm2.64-.009v3.53h1.087v-3.53zm3.714 3.548v-3.527h-1.075v3.532z"
					className="prefix__cls-1"
					transform="translate(-10.969 -31.881)"
				/>
				<path
					id="prefix__Path_3941"
					d="M501.164 908.337h-12.157a.832.832 0 01.577-1.175c1.687-.688 3.348-1.44 5.007-2.193a1.1 1.1 0 011.011.008c1.66.751 3.321 1.5 5.008 2.192a.817.817 0 01.554 1.168z"
					className="prefix__cls-1"
					transform="translate(-7.65)"
				/>
				<path
					id="prefix__Path_3942"
					d="M480.242 992.234h5.4c-.346.547-.644 1.036-.965 1.51a.483.483 0 01-.333.2c-1.357.014-2.713.009-4.1.009z"
					className="prefix__cls-1"
					transform="translate(0 -76.856)"
				/>
				<path
					id="prefix__Path_3943"
					d="M554.855 992.1h5.387v1.731c-1.361 0-2.736.005-4.11-.01a.5.5 0 01-.319-.223c-.313-.458-.601-.932-.958-1.498z"
					className="prefix__cls-1"
					transform="translate(-65.625 -76.741)"
				/>
				<path
					id="prefix__Path_3944"
					d="M522.574 1005.679l.073.577.558.081v.545h-5.141l-.03-.55.564-.063a.661.661 0 01.064-.23c.1-.129.225-.343.344-.345 1.175-.025 2.352-.015 3.568-.015z"
					className="prefix__cls-1"
					transform="translate(-33.239 -88.68)"
				/>
				<path
					id="prefix__Path_3945"
					d="M532.282 992.8v-.548h1.758v.548z"
					className="prefix__cls-1"
					transform="translate(-45.771 -76.869)"
				/>
			</g>
		</svg>
	)
}

export default SvgComponent
