import React from 'react'
import { LoginImage, Logo } from '../../images';
import styles from './AuthPage.module.scss';

const AuthPage = ({ children }) => {
  return (
    <div className={styles.root} style={{ backgroundImage: `url(${LoginImage})` }}>
      <div className={styles.wrapper}>
        <img src={Logo} className={styles.logo} alt="TiToo Logo" />
        <div className={styles.wrapperInner}>
          {children}
        </div>
        <div className={styles.footerLinks}>
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#support">Support</a>
        </div>
      </div>
    </div>
  )
}

export { AuthPage }