import { Sidebar } from '../../../components/common'
import styles from './application-frame.module.scss'

const ApplicationFrame = ({ children }) => {

  return (
    <div className={styles.root}>
      <Sidebar />
      <div className={styles.page}>
        {children}
      </div>
    </div>
  )
}

export { ApplicationFrame }