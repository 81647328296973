import React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 17.892 17.805"
      {...props}
    >
      <path
        fill="#181c32"
        d="M11.125 2.971l3.64 3.64-9.213 9.213-3.638-3.64zm6.4-.878L15.899.47a1.611 1.611 0 00-2.275 0l-1.551 1.555 3.64 3.64 1.814-1.814a1.241 1.241 0 000-1.758zM.01 17.3a.414.414 0 00.5.493l4.057-.982L.929 13.17z"
      />
    </svg>
  )
}

export default SvgComponent