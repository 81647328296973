import React from 'react';
import { NavLink } from 'react-router-dom';
import { Attraction, Explore, LogoSmall, Trip } from '../../../images';
import styles from './sidebar.module.scss'
import { Lang, ROUTES } from '../../../constants';

const MenuItem = React.memo(({ path, title, icon: Icon }) => {
  return (
    <NavLink
      className={styles.link}
      activeClassName={styles.active}
      data-title={title}
      to={path}>
      <Icon />
    </NavLink>
  )
})

const SidebarComponent = React.memo(({ lang }) => {
  const routes = [
    {
      path: ROUTES.ATTRACTIONS,
      icon: Attraction,
      title: lang.ATTRACTIONS
    },
    {
      path: ROUTES.TRIPS,
      icon: Trip,
      title: lang.TRIPS
    },
    {
      path: ROUTES.POSTS,
      icon: Explore,
      title: lang.POSTS
    },
  ]

  return (
    <div className={styles.sidebar}>
      <div className={styles.header}>
        <NavLink to={ROUTES.ROOT}>
          <img src={LogoSmall} className={styles.logo} alt="TiToo Logo" />
        </NavLink>
      </div>
      <div className={styles.menu}>
        {routes.map(item => <MenuItem key={item.path} {...item} />)}
      </div>
    </div>
  )
})

SidebarComponent.defaultProps = {
  lang: Lang.SIDEBAR,
  organization: {},
  rootItems: []
}

export { SidebarComponent }