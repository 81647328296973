import React from "react"

function SvgComponent(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="1em"
			height="1em"
			viewBox="0 0 15.027 18.452"
			{...props}
		>
			<path
				fill="#181c32"
				d="M6.645 0h1.733a.718.718 0 00.1.033 2.281 2.281 0 011.446.873 2.354 2.354 0 01.482 1.4h3.435a1.146 1.146 0 011.167.991 4.931 4.931 0 01.013.648.591.591 0 01-.662.668h-.494v11.476a2.3 2.3 0 01-2.374 2.363H3.567a2.305 2.305 0 01-2.411-2.4V4.792c0-.057-.005-.115-.008-.181H.591a.574.574 0 01-.59-.594v-.5A1.153 1.153 0 011.217 2.31h3.4l.009-.138A2.313 2.313 0 016.3.092c.114-.036.233-.063.345-.092zM4.623 10.965c0-1.514 0-3.027-.005-4.541a.868.868 0 00-.116-.422.532.532 0 00-.62-.21.568.568 0 00-.414.568v9.154a.594.594 0 00.21.495.542.542 0 00.608.081.565.565 0 00.339-.549q-.002-2.287-.002-4.576zm3.467-.014V6.356a.576.576 0 00-1.138-.142 1.258 1.258 0 00-.016.269v9.1a.571.571 0 00.531.562.577.577 0 00.608-.456 1.235 1.235 0 00.013-.251l.002-4.487zm2.312-.013v4.577a.579.579 0 101.154.009V6.407a.581.581 0 10-1.154.009l-.001 4.522zM5.774 2.306h3.253c.206 0 .234-.031.216-.24a1.012 1.012 0 00-.993-.912q-.739-.009-1.477 0a.99.99 0 00-.937.687 2.828 2.828 0 00-.063.465z"
			/>
		</svg>
	)
}

export default SvgComponent