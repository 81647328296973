import { DEFAULT_USER_PERMISSION, EVERYONE_PERMISSIONS_GROUP } from '../../constants'

export const getPermission = ({ auth }) => {
  let { tokenDetails } = auth;

  return tokenDetails.permissions || DEFAULT_USER_PERMISSION
}

export const isHavePermission = ({ auth, permissions = EVERYONE_PERMISSIONS_GROUP }) => {
  let { tokenDetails } = auth;

  return permissions.includes(tokenDetails.permissions || DEFAULT_USER_PERMISSION)
}

export const isAuthorized = ({ auth }) => {
  let { tokenDetails } = auth;

  if (tokenDetails && tokenDetails.exp) {
    return !(1000 * tokenDetails.exp - Date.now() < 5000);
  }
  return false;
}

export const getToken = ({ auth }) => {
  let { token } = auth;
  return token && token;
}
