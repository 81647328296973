import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21.942 24.566"
      {...props}
    >
      <g fill="#78232f">
        <path
          data-name="Path 2354"
          d="M20.679 20.194a.332.332 0 10-.235.1.335.335 0 00.235-.1z"
        />
        <path
          data-name="Path 2355"
          d="M20.445 21.57a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5zm0 2.332a.834.834 0 11.834-.834.835.835 0 01-.834.834z"
        />
        <path
          data-name="Path 2356"
          d="M20.777 18.015v-6.582a1.54 1.54 0 00-1.538-1.538h-5.013l.086-.211a1.4 1.4 0 00-1.325-1.926h-1.155c-.167 0-.336-.008-.5-.024A3.067 3.067 0 018.677 5.34a2.205 2.205 0 00-.241-.607l-.1-.178.2-.049a2.286 2.286 0 10-2.48-1.015l.109.175-.2.055a2.2 2.2 0 00-1.392 1.147 11.732 11.732 0 00-1.2 5.144v1.787a6.121 6.121 0 00.516 2.463l.019.043-.577 3.156-2.331 3a.291.291 0 00-.017.025v.007l-.9 1.454a.562.562 0 00.183.773l2.914 1.8a.334.334 0 00.251.04.33.33 0 00.206-.149 2.261 2.261 0 00.278-1.719 2.3 2.3 0 00-.177-.49l-.046-.093 1.448-1.631-.225 1.768v1.99a.332.332 0 00.332.332h3.654a.332.332 0 00.332-.332 2.26 2.26 0 00-.905-1.815l-.074-.056.8-4.833a5.955 5.955 0 00-.2-2.754l-.961-3.03V9.507l.227.122a7.859 7.859 0 003.717.931h1.065l-.1.22a1.519 1.519 0 00-.147.655V24.22a.332.332 0 00.664 0V14.169h6.8v3.846a.332.332 0 10.664 0zM7.992.664a1.621 1.621 0 11-1.621 1.621A1.622 1.622 0 017.992.664zm-4.714 22.89l-.046.213-2.529-1.561.672-1.088 1.173.724a1.614 1.614 0 01.729 1.712zm.04-2.024l-1.561-.958 2.151-2.769a.331.331 0 00.065-.144l.443-2.422.211.279a6.138 6.138 0 00.7.777l.314.294-.347 2.721zm5.158 2.166l.073.205H5.578v-1.279h1.379a1.615 1.615 0 011.518 1.074zm-.259-8.72a5.286 5.286 0 01.177 2.445l-.747 4.537H5.623l.7-5.456a.333.333 0 00-.1-.284l-.437-.41a5.5 5.5 0 01-1.715-3.512l-.016-.168H7.32zm4.8-5.082h-1.181A7.134 7.134 0 017.761 8.63l-.009-.006a7.291 7.291 0 01-1-.837.332.332 0 10-.469.47 8 8 0 00.885.763l.062.046v2.4H4.046v-1.455a11.065 11.065 0 011.127-4.852 1.529 1.529 0 012.863.331 3.731 3.731 0 003.236 2.9c.187.018.377.028.565.028h1.155a.756.756 0 01.762.71.737.737 0 01-.736.764zm7.1 3.61h-6.8v-2.072a.875.875 0 01.874-.874h5.054a.875.875 0 01.874.874z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
