import { connect } from "react-redux";
import { ATTRACTIONS_EDITOR_NEW } from "../../../constants/attractions.constants";
import { getAttractionById } from "../../../store/selectors/attractions.selector";
import {
  createNewAttraction,
  fetchAttractionById,
  updateAttraction,
  publishAttraction,
} from "../../../store/actions/attractions.actions";
import { AttractionEditorPageComponent } from "./attractions-editor.component";

const mapStateToProps = ({ attractions }, { match }) => {
  let { id } = match?.params;
  return {
    id,
    isNew: id === ATTRACTIONS_EDITOR_NEW,
    attraction: getAttractionById({ attractions, id }) || {},
  };
};

const mapDispatchToProps = {
  createNewAttraction,
  fetchAttractionById,
  updateAttraction,
  publishAttraction,
};

export const AttractionEditorPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttractionEditorPageComponent);
