import { connect } from 'react-redux';
import { ROUTES } from '../../constants/routes';
import { isAuthorized } from '../../store/selectors/auth.selector';
import { Route } from './Route.component';
// import UserService from 'shared/services/user.service'
// import { isHavePermission } from 'shared/store/selectors/auth.selector';

const mapStateToProps = ({ auth }, { permissions }) => {
  return {
    // rootPath: ROUTES.HOME,
    // isHavePermissions: isHavePermission({ auth, permissions }),
    isAllowedRoute: isAuthorized({ auth }),
    redirectRoute: ROUTES.WELCOME
  }
}

export const PrivateRoute = connect(mapStateToProps)(Route)