import { Lang } from '../../constants'

const initialState = Lang;

const actionsRepo = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload)
  }

  return state;
};
