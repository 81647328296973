
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const toBase64 = (text) => Buffer.from(text).toString('base64')

const queryString = (params) => Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');

const convertToSec = (time = Date.now()) => time / 1000 | 0;

const nowSEC = () => Date.now() / 1000 | 0;

export {
  asyncForEach,
  timeout,
  toBase64,
  queryString,
  convertToSec,
  nowSEC
}