class localStorageHelper {
  async get(key) {
    try {
      const jsonValue = await localStorage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (err) {
      return null;
    }
  }

  async set(key, value) {
    try {
      let jsonValue = JSON.stringify(value);
      await localStorage.setItem(key, jsonValue);
      return true
    } catch (e) {
      console.log('e', e)
      return false;
    }
  }
}

export default new localStorageHelper();