// Application actions
export const APP_INIT = "[App] Init";
export const INITIAL_STATE = "[App] Set Initial state";
export const APP_FINISH_STEP = "[App] Finish step";
export const APP_IS_READY = "[App] App is ready";
export const FIRST_TIME = "[App] First time";

// Storage actions
export const LOAD_STORAGE = "[Storage] Load storage";
export const UPDATE_STORAGE = "[Storage] Update storage";

// Auth actions
export const AUTH_LOGIN = "[Auth] Login";
export const AUTH_LOGOUT = "[Auth] Logout";
export const AUTH_UPDATE_USER = "[Auth] Update User";
export const AUTH_ATTACH_USER_ID = "[Auth] Attach user id";
export const AUTH_UPDATE_TOKEN = "[Auth] Update token";

// Device Flags
export const UPDATE_DEVICE_FLAGS = "[Flags] Update flag";

// Attractions
export const ATTRACTIONS_FETCH_DATA = "[Attractions] Fetch data";
export const ATTRACTIONS_FETCH_BY_ID = "[Attractions] Fetch by id";
export const ATTRACTIONS_CREATE_NEW = "[Attractions] Create new attraction";
export const ATTRACTIONS_UPDATE = "[Attractions] update attraction";
