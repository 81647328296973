import jwtDecode from 'jwt-decode';
import update from 'immutability-helper';
import {
  AUTH_ATTACH_USER_ID,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_USER,
  INITIAL_STATE,
  AUTH_UPDATE_TOKEN,
} from "../actions";

const initialState = {
  token: null,
  tokenDetails: {},
  user: {},
  id: ''
};

const authLogin = (state, { token, user }) => update(state, {
  $merge: {
    token,
    user,
    tokenDetails: { ...jwtDecode(token) }
  }
});

const authUpdateToken = (state, { token }) => update(state, {
  $merge: {
    token,
    tokenDetails: { ...jwtDecode(token) }
  }
})

const attachUserId = (state, { id }) => update(state, {
  id: { $set: id }
})

const loadInitialState = (state, { auth = {} }) => update(state, {
  $merge: auth
})

const updateUserDetails = (state, { user }) =>
  Object.assign({}, state, { user })


const authLogout = () => {
  return initialState;
}

const actionsRepo = {
  [INITIAL_STATE]: loadInitialState,
  [AUTH_LOGIN]: authLogin,
  [AUTH_UPDATE_TOKEN]: authUpdateToken,
  [AUTH_ATTACH_USER_ID]: attachUserId,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE_USER]: updateUserDetails,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload)
  }

  return state;
};
