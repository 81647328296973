import API from "../../api/API";
import { APPLICATION_STEPS } from "../../constants/applicationSteps";
import { APP_FINISH_STEP, AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE_TOKEN } from "../actions";
import { updateStateToStorage } from "../actions/storage.actions";
import { attachUserId, authInit } from "../actions/auth.actions";
import { getToken } from "../selectors/auth.selector";
// import { finishStep } from "../actions/application.actions";

export const authInitMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === APP_FINISH_STEP && action.payload?.step === APPLICATION_STEPS.PERSIST_STORAGE) {
    let token = getToken(getState())
    if (token) {
      API.setToken(token)
      // dispatch(attachUserId({ id: userService.userId }))
    }

    dispatch(authInit())
    // dispatch(finishStep({ step: APPLICATION_STEPS.AUTHENTICATION_PROCESS }))
  }
}

export const authUpdateToken = () => next => action => {
  if (action.type === AUTH_UPDATE_TOKEN) {
    API.setToken(action.payload.token)
  }

  next(action);
}

export const authLoginProcess = ({ dispatch }) => next => action => {
  if (action.type === AUTH_LOGIN) {
    // Attach user ID
    // dispatch(attachUserId({ id: userService.userId }))
    API.setToken(action.payload.token)
    next(action);
    dispatch(updateStateToStorage())
    // Select organization on login
    // Timeout - for mobile router
    // setTimeout(() => navigate(ROUTES.SWITCH_ORGANIZATION), 0)

  } else {
    next(action);
  }
}

export const authLogoutProcess = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === AUTH_LOGOUT) {
    // Attach user ID
    dispatch(attachUserId({ id: '' }))
    API.setToken(null)
    dispatch(updateStateToStorage())
  }
}


export const authMiddleware = [authInitMiddleware, authLoginProcess, authUpdateToken, authLogoutProcess]