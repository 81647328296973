import { compose, applyMiddleware } from "redux"
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import { applicationMiddleware } from "./application.middleware";
import { attractionsMiddleware } from "./attractions.middleware";
import { authMiddleware } from "./auth.middleware";
import { loggerMiddleware } from "./logger.middleware";
import { storageMiddleware } from "./storage.middleware";

export const middleware = (middlewares = []) =>
  compose(
    composeWithDevTools(
      applyMiddleware(
        thunk,
        loggerMiddleware,
        ...storageMiddleware,
        ...applicationMiddleware,
        ...authMiddleware,
        ...attractionsMiddleware,
        ...middlewares,
      )
    )
  )