import React from "react";
import Lottie from "lottie-react";
import loaderData from "./loader.json";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid",
    },
  };

  return <Lottie style={{ height: "100px" }} {...defaultOptions} />;
};

export { Loader };
