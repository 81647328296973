import React from "react";
import styles from "./location-input.module.scss";
import classnames from "classnames";
import { Select } from "antd";
import { debounce } from "lodash";
import googleMapsApi from "../../../api/google-maps.api";
import { googleAutocomplete } from "./google-maps";

const LocationInput = ({
  value,
  wrapperClass,
  error,
  name,
  onChange,
  ...props
}) => {
  const [options, setOptions] = React.useState();

  const onSearch = debounce(async (address) => {
    setOptions([]);
    if (!address) return false;

    const results = await googleAutocomplete(address);
    if (!results) return false;

    setOptions(
      results.map(({ place_id: value, description: label }) => ({
        value,
        label,
      }))
    );
  }, 500);

  const onSelect = async ({ value, label }) => {
    // console.log(res)
    // return
    if (!value) return false;

    const { data, error } = await googleMapsApi.findLocation({
      place_id: value,
    });

    if (error) {
      return false;
    }

    if (!data?.results?.length) {
      return false;
    }

    let { geometry, place_id: placeId } = data?.results[0];

    let { lat, lng } = geometry.location;

    let location = {
      type: "Point",
      coordinates: [lng, lat],
    };

    onChange({ location, placeId, address: label });
  };

  return (
    <div className={classnames(styles.root, wrapperClass)}>
      <Select
        showSearch
        defaultValue={value}
        options={options}
        showArrow={false}
        filterOption={false}
        onSelect={onSelect}
        onSearch={onSearch}
        labelInValue={true}
        {...props}
      />
      <span className={styles.id}>Place id: {value?.value || "REQUIRED!"}</span>
      <span className={styles.error}>{error}</span>
    </div>
  );
};

LocationInput.defaultProps = {
  value: "",
  name: "",
  error: null,
  wrapperClass: null,
  onChange: () => {},
};

export { LocationInput };
