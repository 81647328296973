import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18.875 22.96"
      {...props}
    >
      <path
        data-name="Path 2343"
        d="M4.508 19.476a1.025 1.025 0 101.025 1.025 1.026 1.026 0 00-1.025-1.025zm0 1.376a.351.351 0 11.351-.351.351.351 0 01-.351.351z"
        fill="#78232f"
      />
      <path
        data-name="Path 2344"
        d="M9.773 20.501a1.025 1.025 0 101.025-1.025 1.026 1.026 0 00-1.025 1.025zm1.376 0a.351.351 0 11-.351-.351.351.351 0 01.351.351z"
        fill="#78232f"
      />
      <path
        data-name="Path 2345"
        d="M18.538 5.03h-1.9a.337.337 0 00-.273.139l-1.569 2.159h-6.8V.337A.337.337 0 007.659 0 7.674 7.674 0 00-.006 7.665a6.28 6.28 0 00.915 3.269.337.337 0 10.576-.351 5.6 5.6 0 01-.806-2.581h13.943a5.624 5.624 0 01-5.607 5.28H6.291a5.691 5.691 0 01-1.05-.1h-.018a5.571 5.571 0 01-2.953-1.594.337.337 0 00-.482.471 6.241 6.241 0 003.2 1.764l2.219 2.608-1.595 1.875a2.465 2.465 0 10.557.386l1.481-1.741 1.488 1.741a2.459 2.459 0 104.124 1.809.337.337 0 10-.674 0 1.785 1.785 0 11-.36-1.075.337.337 0 10.538-.406 2.457 2.457 0 00-3.071-.714L8.1 16.431l2.221-2.611a6.3 6.3 0 004.988-6.044l1.506-2.073h1.723a.337.337 0 000-.674zM3.566 2.006l1.007 1.542a.337.337 0 00.564-.369L4.129 1.636a6.943 6.943 0 013.2-.954v5.851L5.875 4.307a.337.337 0 00-.564.369L6.69 6.787l-5.084-2.6a7.043 7.043 0 011.961-2.178zM.683 7.329a6.943 6.943 0 01.614-2.545l4.971 2.545zm5.611 13.173a1.785 1.785 0 11-1.785-1.785 1.787 1.787 0 011.785 1.784zm1.359-4.59L5.984 13.95c.1 0 .2.007.307.007h2.724q.155 0 .308-.008z"
        fill="#78232f"
      />
    </svg>
  )
}

export default SvgComponent
