import { APP_FINISH_STEP, APP_INIT, APP_IS_READY } from ".";

export const appInit = () => ({
  type: APP_INIT
})

export const finishStep = ({ step }) => ({
  type: APP_FINISH_STEP,
  payload: { step }
})

export const appIsReady = () => ({
  type: APP_IS_READY
})
