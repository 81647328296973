import React from 'react';
import styles from './content-editor.module.scss'
import classnames from 'classnames'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const config = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    // 'uploadImage',
    // 'insertTable',
    'mediaEmbed',
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
    ]
  },
  plugins: [...ClassicEditor.builtinPlugins],
  // simpleUpload: {
  //   // The URL that the images are uploaded to.
  //   uploadUrl: 'http://example.com',

  //   // Enable the XMLHttpRequest.withCredentials property.
  //   withCredentials: true,

  //   // Headers sent along with the XMLHttpRequest to the upload server.
  //   headers: {
  //     Authorization: 'Bearer <JSON Web Token>'
  //   },
  // },
  language: {
    ui: 'en',
    content: 'ar'
  }
}

const ContentEditor = React.memo(({ name, error, touched, wrapperClass, inputStyle, className, value, onChange, ...props }) => {
  return (
    <div className={classnames(styles.root, wrapperClass)}>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={config}
        onBlur={(event, editor) => {
          const value = editor.getData();
          onChange(name, value)
        }}
        {...props}
      />
      <span className={styles.error}>{error}</span>
    </div>
  )
})

ContentEditor.defaultProps = {
  name: '',
  error: null,
  value: '',
  wrapperClass: null,
  className: null,
}

export { ContentEditor }