import { APP_FINISH_STEP, APP_INIT } from "../actions";
import { appIsReady } from "../actions/application.actions";
import { loadState } from '../actions/storage.actions';

export const appInitMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === APP_INIT) {
    let isReady = getState().application?.isReady;

    if (!isReady) {
      dispatch(loadState())
    } else {
      console.error('App init twice')
    }
  }
}

export const appReadyMiddleware = ({ dispatch }) => next => action => {
  next(action);

  // if (action.type === APP_INIT) {
  //   dispatch(loadState())
  // }
}

export const stepsMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type === APP_FINISH_STEP) {
    let state = getState();
    let { parts } = state.application;
    let { step } = action.payload
    let unfinishedParts = Object.values(parts).filter(state => !state)

    if (unfinishedParts.length === 1 && !parts[step]) {
      next(action);
      dispatch(appIsReady())
    } else {
      next(action);
    }
  } else {
    next(action);
  }
}

export const applicationMiddleware = [appInitMiddleware, appReadyMiddleware, stepsMiddleware]