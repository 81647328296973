import { createStore } from "redux";
import { reducers } from './reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter } from "connected-react-router";
import { middleware } from "./middlewares";

export const history = createBrowserHistory();

export const createStoreAction = () => createStore(
  reducers({
    router: connectRouter(history),
  }),
  {},
  middleware([
    routerMiddleware(history)
  ])
)

export const store = createStoreAction();