import React from "react";
import { Tag } from "antd";

const AttractionPublishStatus = ({ isPublished }) => {
  if (isPublished) {
    return <Tag color="green">Published</Tag>;
  }
  return <Tag color="geekblue">Draft</Tag>;
};

AttractionPublishStatus.defaultProps = {
  isPublished: false,
};

export { AttractionPublishStatus };
