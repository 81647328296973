import React from "react";
import { Lang, ROUTES } from "../../../constants";
import styles from "./attractions.module.scss";
import { Table } from "antd";
import { EditIcon, TrashIcon } from "../../../images";
import { NavLink, useHistory } from "react-router-dom";
import _ from "lodash";
import { Button } from "../../../components/core";
import { ATTRACTIONS_EDITOR_NEW } from "../../../constants/attractions.constants";
import { AttractionPublishStatus } from "../../../components/attractions";

const AttractionPageComponent = React.memo(function AttractionPageComponent({
  lang,
  attractions,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const history = useHistory();

  const createNewAttraction = () => {
    history.push(
      ROUTES.ATTRACTION_EDITOR.replace(":id", ATTRACTIONS_EDITOR_NEW)
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "primaryPicture",
      render: (primaryPicture, { name }) => (
        <img src={primaryPicture?.url} alt={name} className={styles.preview} />
      ),
    },
    // {
    //   title: "Name",
    //   dataIndex: "attractionType",
    //   render: (type) => {
    //     let Icon = ATTRACTIONS_GROUP_TYPES_ICONS[type];
    //     return <Icon />;
    //   },
    // },
    {
      title: "Name",
      dataIndex: "name",
      render: (name, { id }) => (
        <NavLink to={`/attractions/${id}`}>{name}</NavLink>
      ),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      // <NavLink to={`/ambassador/${owner.id}`}>
      // </NavLink>
      render: (owner) => `${owner?.firstName} ${owner?.lastName}`,
    },
    {
      title: "Status",
      dataIndex: "isPublished",
      render: (i) => <AttractionPublishStatus isPublished={i} />,
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (id) => (
        <>
          <NavLink to={`/attractions/${id}`}>
            <EditIcon className={styles.icon} />
          </NavLink>
          <TrashIcon className={styles.icon} />
        </>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  return (
    <>
      <div className={styles.header}>
        <h1>{lang.HEADER}</h1>
        <Button
          buttonStyle="primary"
          className={styles.addAttractionButton}
          onClick={createNewAttraction}
        >
          Add new
        </Button>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        rowKey="id"
        loading={_.isNull(attractions)}
        dataSource={attractions}
      />
    </>
  );
});

AttractionPageComponent.defaultProps = {
  lang: Lang.ATTRACTIONS_PAGE,
  attractions: null,
};

export { AttractionPageComponent };
