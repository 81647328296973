import { GOOGLE_MAP_API_BASE_URL as baseURL, GOOGLE_MAP_API_KEY } from "../constants/google.constants";
import { Executer, GET } from "../utils/Executer";
const queryString = require('query-string');


class GoogleMapsAPI {
  constructor() {
    this.client = new Executer({ baseURL })
  }

  async findLocation(query) {
    let stringified = queryString.stringify({ ...query, key: GOOGLE_MAP_API_KEY })
    return await this.client.execute(GET, `/maps/api/geocode/json?${stringified}`)
  }
}


export default new GoogleMapsAPI();