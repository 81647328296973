import React from 'react';
import { ATTRACTION_TYPES } from '../../../constants/attractions.constants';
import { HotelSVG, RestaurantSVG, TripSVG } from '../../../images';
import styles from './attractions-type.module.scss';
import classnames from 'classnames';

const icons = {
  [ATTRACTION_TYPES.HOTEL]: HotelSVG,
  [ATTRACTION_TYPES.RESTAURANT]: RestaurantSVG,
  [ATTRACTION_TYPES.TRIP]: TripSVG,
}

const AttractionsType = ({ name, value, error, onChange }) => {

  const selectType = (e) => {
    onChange(name, e.target?.dataset?.type)
  }

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {Object.values(ATTRACTION_TYPES).map(key => (
          <button
            type='button'
            className={classnames(
              styles.option,
              value === key ? styles.active : {}
            )}
            data-type={key}
            onClick={selectType}
            key={key}>
            <img src={icons[key]} alt={key} />
            {key}
          </button>
        ))}
      </div>
      <span className={styles.error}>{error}</span>
    </div>
  )
}

AttractionsType.defaultProps = {
  value: '',
  error: null,
  onChange: () => { }
}

export { AttractionsType }