import { connect } from 'react-redux';
import { ROUTES } from '../../constants/routes';
import { isAuthorized } from '../../store/selectors/auth.selector';
import { Route } from './Route.component';

const mapStateToProps = ({ auth }) => {
  return {
    isAllowedRoute: !isAuthorized({ auth }),
    redirectRoute: ROUTES.ROOT
  }
}

export const AuthRoute = connect(mapStateToProps)(Route)