import { INITIAL_STATE, LOAD_STORAGE, UPDATE_STORAGE } from ".";
import { debounce } from "lodash";

export const loadState = () => ({
  type: LOAD_STORAGE
})

const updateStateToStorageAction = debounce((dispatch) => {
  dispatch({
    type: UPDATE_STORAGE
  })
}, 500)

export const updateStateToStorage = () => dispatch => {
  updateStateToStorageAction(dispatch)
}

export const applyInitialState = payload => ({
  type: INITIAL_STATE,
  payload
})