import {
  AUTH_ATTACH_USER_ID,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_USER,
  AUTH_UPDATE_TOKEN,
} from ".";
import authenticationServiceApi from "../../api/authentication.service.api";
import {
  APPLICATION_STEPS,
  COLLABORATORS_PERMISSIONS_GROUP,
  Lang,
} from "../../constants";
import { getToken, isAuthorized } from "../selectors/auth.selector";
import { finishStep } from "./application.actions";

export const signIn =
  ({ token, user }) =>
  (dispatch) => {
    dispatch({
      type: AUTH_LOGIN,
      payload: { token, user },
    });
  };

export const signInWithGoogle =
  ({ accessToken }) =>
  async (dispatch) => {
    let res = await authenticationServiceApi.loginWithGoogle({ accessToken });

    if (!res.success) {
      return {
        error: res.error.message,
      };
    }

    let { token, user } = res.data;

    // Validation for collaborator at least
    if (!COLLABORATORS_PERMISSIONS_GROUP.includes(user.permissions)) {
      return {
        error: Lang.ERRORS.USER_DO_NOT_HAVE_PERMISSIONS,
      };
    }

    dispatch({
      type: AUTH_LOGIN,
      payload: { token, user },
    });

    return {};
  };

export const updateToken = ({ token }) => ({
  type: AUTH_UPDATE_TOKEN,
  payload: { token },
});

export const attachEmail =
  ({ email }) =>
  (dispatch) => {
    dispatch({
      type: AUTH_UPDATE_USER,
      payload: { user: { email } },
    });
  };

export const attachUserId = ({ id }) => ({
  type: AUTH_ATTACH_USER_ID,
  payload: { id },
});

export const logout = () => async (dispatch) => {
  dispatch({ type: AUTH_LOGOUT });
};

export const finishAuthStep = () => (dispatch) => {
  dispatch(finishStep({ step: APPLICATION_STEPS.AUTHENTICATION_PROCESS }));
};

export const authInit = () => async (dispatch, getState) => {
  let state = getState();

  if (isAuthorized(state)) {
    console.log("User service - user is authenticated");
    dispatch(finishAuthStep());
    return true;
  }

  console.log("User service - user not authenticated");

  // Check if token exist
  if (getToken(state)) {
    console.log("User service - user was authenticated - FORCE LOGOUT");

    // Force logout
    dispatch(logout());
  }

  // Finish application step
  dispatch(finishAuthStep());
  return false;
};
