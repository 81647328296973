import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      data-name="weel chair"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18.961 22.422"
      {...props}
    >
      <g data-name="Group 283" fill="#78232f">
        <path
          data-name="Path 2350"
          d="M7.935 22.422a7.952 7.952 0 01-5.877-2.609.329.329 0 11.487-.442 7.271 7.271 0 0011.267-.592.693.693 0 00-1.118-.818 5.893 5.893 0 11-7.34-8.773.693.693 0 10-.607-1.245 7.275 7.275 0 00-2.964 10.425.329.329 0 11-.556.352A7.934 7.934 0 014.458 7.352a1.351 1.351 0 011.185 2.427 5.235 5.235 0 106.52 7.793 1.351 1.351 0 112.18 1.595 7.926 7.926 0 01-6.408 3.254z"
        />
        <path
          data-name="Path 2351"
          d="M9.346 4.205a2.1 2.1 0 112.1-2.1 2.105 2.105 0 01-2.1 2.1zm0-3.547a1.444 1.444 0 101.444 1.444A1.446 1.446 0 009.346.658z"
        />
        <path
          data-name="Path 2352"
          d="M17.519 16.035a.329.329 0 01-.308-.214l-.663-1.777a1.348 1.348 0 00-1.261-.882h-4.269a.329.329 0 01-.329-.329V9.717a.329.329 0 01.329-.329h3.988a.695.695 0 000-1.389h-3.988a.329.329 0 01-.329-.329v-.674a1.343 1.343 0 00-2.687 0v3.557a.329.329 0 11-.658 0V6.996a2 2 0 114 0v.345h3.659a1.353 1.353 0 110 2.705h-3.656v2.457h3.94a2.008 2.008 0 011.879 1.313l.662 1.775a.33.33 0 01-.308.444z"
        />
        <path
          data-name="Path 2353"
          d="M17.607 20.225a1.355 1.355 0 01-1.269-.888l-1.219-3.245a1.349 1.349 0 00-1.262-.883H9.346a2 2 0 01-2-2V11.87a.329.329 0 11.658 0v1.338a1.345 1.345 0 001.343 1.343h4.511a2.008 2.008 0 011.879 1.312l1.219 3.245a.694.694 0 101.3-.476l-.587-1.574a.33.33 0 11.617-.23l.588 1.576a1.354 1.354 0 01-1.27 1.821z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
