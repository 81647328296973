import React from "react";
import { connect } from "react-redux";
import { Router } from "./app.router";
import { ConnectedRouter } from "connected-react-router";
import { appInit } from "../../store/actions/application.actions";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "../../constants/google.constants";

// Set global
global.platform = "web";

const AppComponent = React.memo(({ history, appInit, isReady }) => {
  React.useEffect(() => {
    const init = async () => {
      if (!isReady) {
        console.log("App - Init start");
        appInit();
      }
    };

    init();
  });

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <ConnectedRouter history={history}>
        {isReady && <Router />}
      </ConnectedRouter>
    </GoogleOAuthProvider>
  );
});

AppComponent.defaultProps = {
  appInit: () => {},
  isReady: false,
};

const mapStateToProps = ({ application }) => ({
  isReady: application.isReady,
});

export const App = connect(mapStateToProps, { appInit })(AppComponent);
