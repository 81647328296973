export const Lang = {
  WELCOME_SCREEN: {
    HEADER: "Sign in to TiToo",
    CONTENT: "Please sing in with your personal email",
    SIGNIN_WITH_GOOGLE: "Continue with Google",
  },

  SIDEBAR: {
    ATTRACTIONS: "Attractions",
    TRIPS: "Trips",
    POSTS: "Posts",
  },

  ATTRACTIONS_PAGE: {
    HEADER: "Attractions",
  },

  ATTRACTIONS_EDITOR_PAGE: {
    ATTRACTION_NAME: "Insert attraction name",
    SAVE: "Save",
    CREATE: "Create",
    PUBLISH: "Publish",
  },

  ERRORS: {
    USER_DO_NOT_HAVE_PERMISSIONS:
      "You not allowed to login, contact us: talkwithus@titoo.app",
  },
  FILE_UPLOADER: {
    PLACEHOLDER: "Drag your file here or click to upload",
    REJECTED: "This file will be rejected",
    DROP_FILES: "All files will be accepted",
    ACTIVE_FILES: "{length} loaded and ready for upload",
  },
};
