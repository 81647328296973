import { combineReducers } from 'redux';
import applicationReducer from './application.reducer';
import attractionsReducer from './attractions.reducer';
import authReducer from './auth.reducer';
import langReducer from './lang.reducer';

export const reducers = (reducers = {}) =>
  combineReducers({
    lang: langReducer,
    auth: authReducer,
    application: applicationReducer,
    attractions: attractionsReducer,
    ...reducers
  })