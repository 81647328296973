import React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      {...props} >
      <path
        fill="#78232f"
        d="M9 18a9 9 0 119-9 9.01 9.01 0 01-9 9zm5.4-14.4L7.029 7.029 3.6 14.4l7.371-3.43L14.4 3.6zM9 9.99A.99.99 0 119.99 9a.991.991 0 01-.99.99z"
      />
    </svg>
  )
}

export default SvgComponent
