const { currentEnvironment } = require("../utils/environment");

const urls = {
  development: "http://localhost:3300",
  staging: "https://staging.api.titoo.app",
  production: "https://api.titoo.app",
};

export const baseURL = urls[currentEnvironment()];

export const AUTHENTICATION_SERVICE_URL = "/v1/authentication";
export const ATTRACTIONS_SERVICE_URL = "/v1/attractions";
