import { POST } from "../utils/Executer";
import API from "./API";
import { AUTHENTICATION_SERVICE_URL as servicePath } from "../constants";

class AuthenticationServiceAPI {
  async loginWithEmail({ email }) {
    return await API.client.execute(POST, `${servicePath}`, { email });
  }

  async loginWithGoogle({ accessToken }) {
    return await API.client.execute(
      POST,
      `${servicePath}/signin/google/access-token`,
      { accessToken }
    );
  }
}

export default new AuthenticationServiceAPI();
