import {
  ATTRACTIONS_CREATE_NEW,
  ATTRACTIONS_FETCH_BY_ID,
  ATTRACTIONS_FETCH_DATA,
  ATTRACTIONS_UPDATE,
} from ".";
import attractionServiceApi from "../../api/attraction.service.api";

export const fetchAttractions = () => async (dispatch) => {
  let { data } = await attractionServiceApi.fetchAttractions();

  let { attractions } = data;

  dispatch({
    type: ATTRACTIONS_FETCH_DATA,
    payload: {
      attractions,
    },
  });
};

export const fetchAttractionById =
  ({ id }) =>
  async (dispatch) => {
    // TODO: fetch
    let { data } = await attractionServiceApi.fetchAttractionById({
      id,
    });

    let { attraction } = data;

    dispatch({
      type: ATTRACTIONS_FETCH_BY_ID,
      payload: {
        id,
        attraction,
      },
    });
  };

export const createNewAttraction =
  ({ values }) =>
  async (dispatch) => {
    let { data, error } = await attractionServiceApi.createNewAttraction({
      values,
    });

    if (error) {
      return {
        error,
        message: data.message,
      };
    }

    let { attraction } = data;

    dispatch({
      type: ATTRACTIONS_CREATE_NEW,
      payload: {
        attraction,
      },
    });

    return {
      attraction,
    };
  };

export const updateAttraction =
  ({ id, values }) =>
  async (dispatch) => {
    let { data, error } = await attractionServiceApi.updateAttraction({
      id,
      values,
    });

    if (!error) {
      return {
        error,
        message: data.message,
      };
    }

    let { attraction } = data;

    dispatch({
      type: ATTRACTIONS_UPDATE,
      payload: {
        attraction,
      },
    });

    return {
      attraction,
    };
  };

export const publishAttraction =
  ({ id }) =>
  async (dispatch) => {
    let { data, error } = await attractionServiceApi.publishAttraction({
      id,
    });

    if (!error) {
      return {
        error,
        message: data.message,
      };
    }

    let { attraction } = data;

    dispatch({
      type: ATTRACTIONS_UPDATE,
      payload: {
        attraction,
      },
    });

    return {
      attraction,
    };
  };
