import React from 'react';
import styles from './Input.module.scss'
import classnames from 'classnames'

const Input = React.memo(({ error, touched, wrapperClass, inputStyle, ...props }) => {
  return (
    <div className={classnames(styles.root, wrapperClass)}>
      <input className={classnames(styles.input, styles[inputStyle])} {...props} />
      <span className={styles.error}>{error}</span>
    </div>
  )
})

Input.defaultProps = {
  error: null,
  wrapperClass: null,
  inputStyle: 'user',
}

export { Input }