import React from "react";
import {
  ATTRACTIONS_GROUP_TYPES_ICONS,
  GROUP_TYPES,
} from "../../../constants/attractions.constants";
import styles from "./attractions-group-types.module.scss";
import classnames from "classnames";
import update from "immutability-helper";

const AttractionsGroupTypes = ({ name, value, error, onChange }) => {
  const [currentValue, setCurrentValue] = React.useState(value);

  React.useEffect(() => {
    onChange(name, currentValue);
  }, [onChange, currentValue, name]);

  const selectType = (e) => {
    // onChange(name,
    let value = e.target?.dataset?.group;
    let index = currentValue.findIndex((v) => v === value);

    if (index === -1) {
      setCurrentValue((v) =>
        update(v, {
          $push: [value],
        })
      );
    } else {
      setCurrentValue((v) =>
        update(v, {
          $splice: [[index, 1]],
        })
      );
    }
  };

  return (
    <div className={styles.root}>
      {Object.values(GROUP_TYPES).map((key) => {
        let Icon = ATTRACTIONS_GROUP_TYPES_ICONS[key];
        return (
          <button
            type="button"
            className={classnames(
              styles.option,
              currentValue.includes(key) ? styles.active : {}
            )}
            data-group={key}
            onClick={selectType}
            key={key}
          >
            <Icon className={styles.icon} />
            {key}
          </button>
        );
      })}
      <span className={styles.error}>{error}</span>
    </div>
  );
};

AttractionsGroupTypes.defaultProps = {
  name: "",
  value: [],
  error: null,
  onChange: () => {},
};

export { AttractionsGroupTypes };
