import React from "react";
import { Lang } from "../../../constants";
import { Button, Loader } from "../../../components/core";
import styles from "./Welcome.module.scss";
import { AuthPage } from "../AuthPage.component";
import { GoogleIcon } from "../../../images";
import { useGoogleLogin } from "@react-oauth/google";

const WelcomePageComponent = React.memo(({ lang, signInWithGoogle }) => {
  const [error, setError] = React.useState(null);
  const [loading, setLoadingState] = React.useState(false);

  const onSuccess = React.useCallback(
    async (res1) => {
      console.log(res1);
      const { access_token } = res1;
      setError("");
      setLoadingState(true);

      let res = await signInWithGoogle({ accessToken: access_token });

      setLoadingState(false);

      if (res?.error) {
        setError(res.error);
      }
    },
    [signInWithGoogle]
  );

  const loginHandler = useGoogleLogin({
    onSuccess,
  });

  const renderLogin = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <Button size="xlarge" onClick={loginHandler}>
        <img className={styles.google} src={GoogleIcon} alt="Google Signin" />
        {lang.SIGNIN_WITH_GOOGLE}
      </Button>
    );
  };

  return (
    <AuthPage>
      <h1 className={styles.header}>{lang.HEADER}</h1>
      <p className={styles.content}>{lang.CONTENT}</p>

      {renderLogin()}
      {error && <span className={styles.error}>{error}</span>}
    </AuthPage>
  );
});

WelcomePageComponent.defaultProps = {
  lang: Lang.WELCOME_SCREEN,
  signInWithGoogle: () => {},
};

export { WelcomePageComponent };
