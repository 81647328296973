import { APP_IS_READY, AUTH_LOGIN } from "../actions";
import { fetchAttractions } from "../actions/attractions.actions";
import { isAuthorized } from "../selectors/auth.selector";

export const attractionFetchDataMiddleware = ({ dispatch, getState }) => next => async action => {
  next(action);

  if (action.type === AUTH_LOGIN || action.type === APP_IS_READY) {
    if (!isAuthorized(getState())) return false;

    dispatch(fetchAttractions())
  }
}

export const attractionsMiddleware = [attractionFetchDataMiddleware]