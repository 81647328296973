import {
  BonfireMark,
  CampMark,
  FlagMark,
  GeneralMark,
  ParkingMark,
  PicnicMark,
  babiesIcon,
  DisabledIcon,
  DogsMark,
  EldersIcon,
  KidsIcon,
  TravelersIcon,
} from "../images";

import {
  RomanticIcon,
  BabyCarriageIcon,
  WaterIcon,
  SleepIcon,
  FamiliesIcon,
  ProsIcon,
  ViewIcon,
  HotSpringsIcon,
  JeepIcon,
  BikeIcon,
  CoffeeStopIcon,
  AccessibleIcon,
  FireAllowedIcon,
  SpecialIcon,
} from "../images/attraction-properties";

export const ATTRACTIONS_EDITOR_NEW = "new";

export const ATTRACTION_TYPES = {
  TRIP: "trip",
  RESTAURANT: "restaurant",
  HOTEL: "hotel",
};

export const GROUP_TYPES = {
  BABIES: "babies",
  KIDS: "kids",
  ELDERS: "elders",
  TRAVELERS: "travelers",
  DISABLED: "disabled",
  DOGS: "dogs",
};

export const POINT_OF_INTEREST_TYPES = {
  BONFIRE: "BONFIRE",
  CAMP: "CAMP",
  PICNIC: "PICNIC",
  FLAG: "FLAG",
  PARKING: "PARKING",
  GENERAL: "GENERAL",
};

export const ATTRACTION_PROPERTIES = {
  ROMANTIC: "romantic",
  BABY_CARRIAGE: "babyCarriage",
  WATER: "water",
  SLEEP: "sleep",
  FAMILIES: "families",
  TRAVELERS: "travelers",
  VIEWS: "views",
  HOT_SPRINGS: "hotSprings",
  JEEPS: "jeeps",
  BIKES: "bikes",
  COFFEE_STOP: "coffeeStop",
  ACCESSIBLE: "accessible",
  ALLOWED_FIRE: "fireAllowed",
  SPECIAL: "special",
  SPACIAL: "spacial", // TODO: remove after update mongo
};

export const POINT_OF_INTEREST_MARKS = {
  [POINT_OF_INTEREST_TYPES.BONFIRE]: BonfireMark,
  [POINT_OF_INTEREST_TYPES.CAMP]: CampMark,
  [POINT_OF_INTEREST_TYPES.PICNIC]: PicnicMark,
  [POINT_OF_INTEREST_TYPES.FLAG]: FlagMark,
  [POINT_OF_INTEREST_TYPES.PARKING]: ParkingMark,
  [POINT_OF_INTEREST_TYPES.GENERAL]: GeneralMark,
};

export const ATTRACTIONS_GROUP_TYPES_ICONS = {
  [GROUP_TYPES.KIDS]: KidsIcon,
  [GROUP_TYPES.ELDERS]: EldersIcon,
  [GROUP_TYPES.TRAVELERS]: TravelersIcon,
  [GROUP_TYPES.BABIES]: babiesIcon,
  [GROUP_TYPES.DISABLED]: DisabledIcon,
  [GROUP_TYPES.DOGS]: DogsMark,
};

export const ATTRACTION_PROPERTIES_ICONS = {
  [ATTRACTION_PROPERTIES.ROMANTIC]: RomanticIcon,
  [ATTRACTION_PROPERTIES.BABY_CARRIAGE]: BabyCarriageIcon,
  [ATTRACTION_PROPERTIES.WATER]: WaterIcon,
  [ATTRACTION_PROPERTIES.SLEEP]: SleepIcon,
  [ATTRACTION_PROPERTIES.FAMILIES]: FamiliesIcon,
  [ATTRACTION_PROPERTIES.TRAVELERS]: ProsIcon,
  [ATTRACTION_PROPERTIES.VIEWS]: ViewIcon,
  [ATTRACTION_PROPERTIES.HOT_SPRINGS]: HotSpringsIcon,
  [ATTRACTION_PROPERTIES.JEEPS]: JeepIcon,
  [ATTRACTION_PROPERTIES.BIKES]: BikeIcon,
  [ATTRACTION_PROPERTIES.COFFEE_STOP]: CoffeeStopIcon,
  [ATTRACTION_PROPERTIES.ACCESSIBLE]: AccessibleIcon,
  [ATTRACTION_PROPERTIES.ALLOWED_FIRE]: FireAllowedIcon,
  [ATTRACTION_PROPERTIES.SPECIAL]: SpecialIcon,
};
