import _ from 'lodash';
import React from 'react';
import { Route as RouteComponent, Redirect } from 'react-router-dom';

const Route = React.memo(({ component: Component, isAllowedRoute, redirectRoute, children, isHavePermissions = null, rootPath, ...rest }) => {
  return (
    <RouteComponent
      {...rest}
      render={props => {
        if (!isAllowedRoute) {
          return (
            <Redirect
              to={{
                pathname: redirectRoute,
                state: { from: props.location }
              }} />
          )
        }

        if (!_.isNull(isHavePermissions) && !isHavePermissions) {
          return (
            <Redirect
              to={{
                pathname: rootPath,
                state: { from: props.location }
              }} />
          )
        }

        if (Component) {
          return <Component children={children} {...props} />
        }

        return children
      }}
    />
  )
})

export { Route }