import React from "react";
import styles from "./attractions-map.module.scss";
import { Map, Layer, Marker, Source } from "react-map-gl";
import { setRTLTextPlugin } from "mapbox-gl";
// import DrawControl, {
//   DrawLineStringMode,
//   DrawPointMode,
//   Editor,
// } from "react-map-gl-draw";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
// import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { AttractionMark, MarkerIcon, PolylineIcon } from "../../../images";
import { Button } from "../../core";
import { POINT_OF_INTEREST_MARKS } from "../../../constants/attractions.constants";
import { LOCATION_TYPE } from "../../../constants";

const mapProps = {
  mapboxAccessToken:
    "pk.eyJ1IjoiZmFrZXVzZXJnaXRodWIiLCJhIjoiY2pwOGlneGI4MDNnaDN1c2J0eW5zb2ZiNyJ9.mALv0tCpbYUPtzT7YysA2g",
  minZoom: 2,
  style: {
    height: 500,
    width: "100%",
  },
};

const initialViewState = {
  longitude: -122.4,
  latitude: 37.8,
  zoom: 16,
};

setRTLTextPlugin(
  "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.2/mapbox-gl-rtl-text.js"
);

const drawControls = {
  point: false,
  line_string: false,
  trash: false,
  combine_features: false,
  uncombine_features: false,
  polygon: false,
};

const mapStyles = {
  SATELLITE: "mapbox://styles/mapbox/satellite-streets-v11",
  OUTDOOR: "mapbox://styles/mapbox/outdoors-v11",
};

const PAINT_PROPERTIES = {
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#FFBF9A",
    "line-width": 8,
  },
};

const AttractionsMap = React.memo(function AttractionsMap({
  location,
  onCreatePointOfInterest,
  pointsOfInterest,
}) {
  const mapRef = React.useRef();
  const drawerRef = React.useRef();

  const [mapStyle, setMapStyle] = React.useState(mapStyles.SATELLITE);
  const [drawMode, setDrawMode] = React.useState(false);
  const [viewState, setViewState] = React.useState(initialViewState);

  const setCenter = (loc = location) => {
    console.log("loc?.coordinates", loc?.coordinates);
    if (!loc?.coordinates) return false;
    mapRef?.current?.easeTo({
      center: loc.coordinates,
      zoom: 16,
    });
  };

  React.useEffect(() => {
    if (location?.coordinates) {
      setCenter(location);
    }
  }, [location]);

  const onMapLoaded = (map) => {
    if (location?.coordinates) {
      setTimeout(setCenter, 1000);
    }
  };

  const startDraw = (mode) => {
    setDrawMode(true);
    // setModeHandler(mode);
  };

  const finishDraw = () => {
    setDrawMode(false);
    // drawerRef.current.draw.changeMode("simple_select");

    let { features = [] } = drawerRef.current.draw.getAll();

    if (features.length > 0) {
      onCreatePointOfInterest({
        location: features[0].geometry,
      });
    }

    drawerRef.current.draw.deleteAll();
  };

  const renderPointsOfInterest = ({ id, location, type }) => {
    if (location.type === LOCATION_TYPE.LineString) {
      return (
        <Source id={id} key={id} type="geojson" data={location}>
          <Layer type="line" {...PAINT_PROPERTIES} />
        </Source>
      );
    }

    const Icon =
      POINT_OF_INTEREST_MARKS[type] || POINT_OF_INTEREST_MARKS.GENERAL;

    return (
      <Marker
        key={id}
        longitude={location?.coordinates[0]}
        latitude={location?.coordinates[1]}
        anchor="bottom"
      >
        <Icon className={styles.mapIcon} />
      </Marker>
    );
  };

  const renderDrawMode = () => {
    if (!drawMode)
      return (
        <>
          <Button
            type="button"
            className={styles.drawButton}
            // onClick={() => startDraw(DrawPointMode)}
          >
            <MarkerIcon className={styles.mapIcon} />
          </Button>
          <Button
            type="button"
            className={styles.drawButton}
            // onClick={() => startDraw(DrawLineStringMode)}
          >
            <PolylineIcon className={styles.mapIcon} />
          </Button>
        </>
      );

    return (
      <Button type="button" onClick={finishDraw}>
        Finish draw
      </Button>
    );
  };

  return (
    <div className={styles.root}>
      <Map //Map
        ref={mapRef}
        {...mapProps}
        mapStyle={mapStyle}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        onLoad={onMapLoaded}
      >
        {/* <DrawControl ref={drawerRef} controls={drawControls} />
         */}

        {location?.coordinates && (
          <Marker
            latitude={location?.coordinates[1]}
            longitude={location?.coordinates[0]}
            anchor="bottom"
          >
            <AttractionMark className={styles.mapIcon} />
          </Marker>
        )}

        {pointsOfInterest?.map(renderPointsOfInterest)}
      </Map>

      <div className={styles.actions}>
        <div className={styles.drawer}>{/* {renderDrawMode()} */}</div>
        <div className={styles.mapStyles}>
          <Button
            type="button"
            className={styles.mapStylesButton}
            onClick={() => setMapStyle(mapStyles.SATELLITE)}
          >
            Satellite
          </Button>

          <Button
            type="button"
            className={styles.mapStylesButton}
            onClick={() => setMapStyle(mapStyles.OUTDOOR)}
          >
            Outdoor
          </Button>
        </div>
      </div>
    </div>
  );
});

AttractionsMap.defaultProps = {
  location: null,
  onCreatePointOfInterest: () => {},
  pointOfInterest: [],
};

export { AttractionsMap };
