export const googleAutocomplete = async text =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Need valid text input")
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === "undefined") {
      return reject("Need valid window object")
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { input: text, language: "iw" },
        resolve
      )
    } catch (e) {
      reject(e)
    }
  })

// export const googleGetPlaceDetails = async placeId =>
//   new Promise((resolve, reject) => {
//     if (!placeId) {
//       return reject("Need valid place_id input")
//     }

//     // for use in things like GatsbyJS where the html is generated first
//     if (typeof window === "undefined") {
//       return reject("Need valid window object")
//     }

//     try {
//       new window.google.maps.places.PlaceService().GetPlaceDetails(
//         { placeId, language: "iw" },
//         resolve
//       )
//     } catch (e) {
//       reject(e)
//     }
//   })