import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 172 140"
      {...props}
    >
      <g data-name="Group 696" transform="translate(-1345 -869)">
        <path
          data-name="Line 8"
          fill="none"
          stroke="#ffbf9a"
          strokeWidth={5}
          d="M1399.5 892.5l61 53.5"
        />
        <path
          data-name="Line 9"
          fill="none"
          stroke="#ffbf9a"
          strokeWidth={5}
          d="M1504.5 882.5l-46 64"
        />
        <path
          data-name="Path 4504"
          d="M1460 934a12 12 0 11-12 12 12 12 0 0112-12z"
          fill="#ffbf9a"
        />
        <path
          data-name="Line 21"
          fill="none"
          stroke="#ffbf9a"
          strokeWidth={5}
          d="M1399.5 892.5l1 67"
        />
        <path
          data-name="Path 4505"
          d="M1400 881a12 12 0 11-12 12 12 12 0 0112-12z"
          fill="#ffbf9a"
        />
        <path
          data-name="Line 22"
          fill="none"
          stroke="#ffbf9a"
          strokeWidth={5}
          d="M1357.5 997.5l43-41.5"
        />
        <circle
          data-name="Ellipse 47"
          cx={12}
          cy={12}
          r={12}
          transform="translate(1387 947)"
          fill="#ffbf9a"
        />
        <circle
          data-name="Ellipse 48"
          cx={12}
          cy={12}
          r={12}
          transform="translate(1345 985)"
          fill="#ffbf9a"
        />
        <circle
          data-name="Ellipse 49"
          cx={12}
          cy={12}
          r={12}
          transform="translate(1493 869)"
          fill="#ffbf9a"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
