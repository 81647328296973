import React from 'react';
import { Switch } from 'react-router';
import { AuthRoute } from '../router/AuthRoute';
import { ROUTES } from '../../constants';
import { PrivateRoute } from '../router/PrivateRoute';
import { WelcomePage } from '../../pages/authentication';
import {
  ApplicationFrame,
  AttractionEditorPage,
  AttractionPage
} from '../../pages/application';

const Router = React.memo(() => {
  return (
    <Switch>
      <AuthRoute exact path={ROUTES.WELCOME} component={WelcomePage} />
      {/* <AuthRoute exact path={ROUTES.OTP_VERIFICATION} component={VerificationPage} /> */}

      <PrivateRoute path={ROUTES.ROOT} component={ApplicationFrame}>
        <PrivateRoute exact path={ROUTES.ROOT} component={() => <h2>hello</h2>} />
        <PrivateRoute exact path={ROUTES.ATTRACTIONS} component={AttractionPage} />
        <PrivateRoute exact path={ROUTES.ATTRACTION_EDITOR} component={AttractionEditorPage} />
        {/* <ApplicationFrame>
        </ApplicationFrame>*/}
        {/* <Redirect exact from={ROUTES.ROOT} to={ROUTES.ROOT} /> */}
      </PrivateRoute>
    </Switch>
  )
})

export { Router }