import React from "react";
import { Upload, Modal } from "antd";
// import styles from "./image-uploader.module.scss";
import { getBase64 } from "../../../utils/images.utils";
import { PlusOutlined } from "@ant-design/icons";
import attractionServiceApi from "../../../api/attraction.service.api";
import _ from "lodash";

const ImageUploader = React.memo(function ImageUploader({
  accept,
  maxCount,
  value,
  name,
  error,
  onChange,
}) {
  const [previewImage, setPreviewImage] = React.useState("");
  const [fileList, setFileList] = React.useState(value);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
  };

  const handleClosePreview = () => setPreviewImage("");

  const onChangeInternal = ({ fileList, event, file }) => {
    setFileList(fileList);

    if (!event) {
      let newList = fileList
        .filter((i) => i.response || i.url || i.id)
        .map(({ response, url, id }) =>
          response ? { ...response } : { id, url }
        );
      onChange(name, newList);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const renderError = () => {
    if (error && fileList.length === 0) {
      return <span>Required at least one image</span>;
    }

    if (error && fileList.length === 1 && fileList[0].status !== "done") {
      return null;
    }

    if (_.isObject(error)) {
      return <span>Image error</span>;
    }

    if (_.isArray(error)) {
      return <span>array error</span>;
    }

    return null;
  };

  return (
    <>
      <Upload
        action={() => {
          return attractionServiceApi.uploadUrl();
        }}
        onChange={onChangeInternal}
        onPreview={handlePreview}
        fileList={fileList}
        listType="picture-card"
        maxCount={maxCount}
        accept={accept}
      >
        {fileList.length < maxCount ? uploadButton : null}
      </Upload>
      <Modal visible={previewImage} footer={null} onCancel={handleClosePreview}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      {renderError()}
    </>
  );
});

ImageUploader.defaultProps = {
  accept: "application/*, audio/*, image/*, video/*",
  maxCount: 1,
  value: [],
  name: "",
  onChange: () => {},
  touched: false,
};

export { ImageUploader };
