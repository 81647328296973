
export const USER_PERMISSIONS = {
  ROOT: 'ROOT',
  ADMIN: 'ADMIN',
  COLLABORATOR: 'COLLABORATOR',
  USER: 'USER',
  ANONYMOUS: 'ANONYMOUS'
}

export const ROOT_PERMISSIONS_GROUP = [USER_PERMISSIONS.ROOT];
export const ADMINS_PERMISSIONS_GROUP = [USER_PERMISSIONS.ADMIN, ...ROOT_PERMISSIONS_GROUP]
export const COLLABORATORS_PERMISSIONS_GROUP = [USER_PERMISSIONS.COLLABORATOR, ...ADMINS_PERMISSIONS_GROUP]
export const USERS_PERMISSIONS_GROUP = [USER_PERMISSIONS.USER, ...COLLABORATORS_PERMISSIONS_GROUP];
export const EVERYONE_PERMISSIONS_GROUP = [USER_PERMISSIONS.ANONYMOUS, ...USERS_PERMISSIONS_GROUP];

export const DEFAULT_USER_PERMISSION = USER_PERMISSIONS.ANONYMOUS