import React from "react";
import { Lang } from "../../../constants";
import styles from "./attractions-editor.module.scss";
import {
  Loader,
  Input,
  ContentEditor,
  LocationInput,
  Button,
  ImageUploader,
} from "../../../components/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ATTRACTION_PROPERTIES,
  ATTRACTION_TYPES,
  GROUP_TYPES,
  POINT_OF_INTEREST_TYPES,
} from "../../../constants/attractions.constants";
import { ROUTES } from "../../../constants";
import {
  AttractionsType,
  AttractionsGroupTypes,
  AttractionsMap,
  AttractionsPointsOfInterest,
  AttractionPublishStatus,
  AttractionsProperties,
} from "../../../components/attractions";
import classnames from "classnames";
import update from "immutability-helper";
import { uuid } from "uuidv4";
import { useHistory } from "react-router-dom";
import { Alert, Checkbox } from "antd";

const POINT_SCHEMA = Yup.object().shape({
  type: Yup.string().required(),
  coordinates: Yup.array().required(),
});

const POINTS_OF_INTEREST_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(200, "Too Long!")
    .required("Required!"),
  location: POINT_SCHEMA,
});

// Schema
let validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(200, "Too Long!")
    .required("Required!"),
  content: Yup.string().required(),
  type: Yup.mixed().required().oneOf(Object.values(ATTRACTION_TYPES)),
  groupsType: Yup.array()
    .of(Yup.mixed().oneOf(Object.values(GROUP_TYPES)))
    .required("Required!"),
  properties: Yup.array()
    .of(Yup.mixed().oneOf(Object.values(ATTRACTION_PROPERTIES)))
    .required("Required!"),
  address: Yup.string().required(),
  placeId: Yup.string().required(),
  location: POINT_SCHEMA,
  pointsOfInterest: Yup.array().of(POINTS_OF_INTEREST_SCHEMA),
  primaryPicture: Yup.object().shape({
    id: Yup.string().required(),
    url: Yup.string().required(),
  }),

  duration: Yup.number().min(0),
  rating: Yup.number().min(0).max(5),
  phone: Yup.string(),
  website: Yup.string().url(),

  isUGC: Yup.boolean(),
});

const AttractionEditorPageComponent = React.memo(
  function AttractionEditorPageComponent({
    id,
    lang,
    attraction,
    isNew,
    createNewAttraction,
    updateAttraction,
    fetchAttractionById,
    publishAttraction,
  }) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [successMessage, setSuccessMessage] = React.useState("");
    const history = useHistory();

    React.useEffect(() => {
      if (!isNew) {
        fetchAttractionById({ id });
      }
    }, [id, fetchAttractionById, isNew]);

    const createAttraction = React.useCallback(
      async ({ values }) => {
        let res = await createNewAttraction({ values });

        if (res.error) {
          return setError(res.message);
        }

        history.push(
          ROUTES.ATTRACTION_EDITOR.replace(":id", res.attraction?.id)
        );
      },
      [createNewAttraction, history]
    );

    const onSubmit = React.useCallback(
      async (values) => {
        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        let res;
        if (isNew) {
          res = await createAttraction({ values });
        } else {
          res = await updateAttraction({ id, values });
        }

        setLoading(false);

        console.log("res", res);

        if (res.error) {
          setError(res.error);
        } else {
          setSuccessMessage("Update succeeded");
          setTimeout(() => setSuccessMessage(""), 5000);
        }
      },
      [createAttraction, isNew, id, updateAttraction]
    );

    const {
      handleSubmit,
      setFieldValue,
      handleChange,
      setValues,
      values,
      errors,
      touched,
    } = useFormik({
      initialValues: attraction || {},
      validationSchema,
      onSubmit,
    });

    React.useEffect(() => {
      if (!isNew && attraction) {
        setValues(attraction);
      }
    }, [attraction, isNew, setValues]);

    const onPlaceSelected = ({ location, placeId, address }) => {
      setFieldValue("location", location);
      setFieldValue("address", address);
      setFieldValue("placeId", placeId);
    };

    const onPrimaryImageChange = (name, value) => {
      setFieldValue("primaryPicture", value[0]);
    };

    const onPictureChange = (name, value) => {
      setFieldValue("pictures", value);
    };

    const publishPost = async () => {
      setLoading(true);
      setError(null);

      let res = await publishAttraction({ id });

      if (res.error) {
        setError(res.message);
      }

      setLoading(false);
    };

    const onCreatePointOfInterest = ({ location }) => {
      let { pointsOfInterest = [] } = values;

      setFieldValue(
        "pointsOfInterest",
        update(pointsOfInterest, {
          $push: [
            {
              type: POINT_OF_INTEREST_TYPES.GENERAL,
              location,
              id: uuid(),
            },
          ],
        })
      );
    };

    if (!values?.id && !isNew) {
      return <Loader />;
    }

    // Populate data
    let { owner, isPublished, updatedAt, publishedBy, publishedAt } =
      attraction;

    const renderActions = () => {
      if (loading) {
        return <Loader />;
      }

      let isCanSave = Object.keys(errors)?.length === 0;

      return (
        <>
          <div className={styles.actionsButtons}>
            <Button
              buttonStyle="primary"
              type="submit"
              disabled={!isCanSave}
              className={styles.actionButton}
            >
              {isNew ? lang.CREATE : lang.SAVE}
            </Button>
            {!isNew && !isPublished && (
              <Button
                buttonStyle="success"
                type="button"
                disabled={!isCanSave}
                onClick={publishPost}
                className={styles.actionButton}
              >
                {lang.PUBLISH}
              </Button>
            )}
          </div>
          {successMessage && (
            <Alert message={successMessage} type="success" showIcon />
          )}
          {error && <Alert message={error} type="error" showIcon />}
        </>
      );
    };

    const renderAttractionInfo = () => {
      if (isNew) return null;

      return (
        <>
          <h4>Status</h4>
          <div className={styles.state}>
            Owner By
            <span>
              {owner.firstName} {owner.lastName}
            </span>
          </div>

          <div className={styles.state}>
            State
            <span>
              <AttractionPublishStatus isPublished={isPublished} />
            </span>
          </div>

          {isPublished && (
            <>
              <div className={styles.state}>
                Published At
                <span>{publishedAt}</span>
              </div>

              <div className={styles.state}>
                Published By
                <span>
                  {publishedBy.firstName} {publishedBy.lastName}
                </span>
              </div>
            </>
          )}
        </>
      );
    };

    return (
      <form onSubmit={handleSubmit} key={updatedAt}>
        <div className={styles.header}>
          <Input
            name="name"
            value={values.name}
            error={errors.name}
            touched={touched.name}
            onChange={handleChange}
            autoFocus={true}
            placeholder={lang.ATTRACTION_NAME}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.editor}>
            <div className={styles.box}>
              <h3>Attraction type</h3>

              <AttractionsType
                name="type"
                value={values.type}
                error={errors.type}
                touched={touched.type}
                onChange={setFieldValue}
              />
            </div>

            <div className={styles.box}>
              <h3>Audience</h3>
              <AttractionsGroupTypes
                name="groupsType"
                value={values.groupsType}
                error={errors.groupsType}
                touched={touched.groupsType}
                onChange={setFieldValue}
              />
            </div>

            <div className={styles.box}>
              <h3>Properties</h3>
              <AttractionsProperties
                name="properties"
                value={values.properties}
                error={errors.properties}
                touched={touched.properties}
                onChange={setFieldValue}
              />
            </div>

            <div className={styles.box}>
              <h3>Content</h3>

              {/* content */}
              <ContentEditor
                value={values.content}
                name="content"
                error={errors.content}
                touched={touched.content}
                onChange={setFieldValue}
                placeholder={lang.FIRST_NAME_PLACEHOLDER}
              />

              <h3>Pictures</h3>
              <ImageUploader
                maxCount={10}
                value={values.pictures?.length ? values.pictures : []}
                error={errors.pictures}
                touched={touched.pictures}
                onChange={onPictureChange}
              />
            </div>

            <div className={styles.box}>
              <h3>Location Details</h3>

              <h4>address</h4>
              {/* <LocationInput /> */}
              <LocationInput
                value={{ value: values.placeId, label: values.address }}
                error={errors.address || errors.placeId}
                onChange={onPlaceSelected}
              />

              <AttractionsMap
                location={values.location}
                onCreatePointOfInterest={onCreatePointOfInterest}
                pointsOfInterest={values.pointsOfInterest}
              />
            </div>

            <div className={styles.box}>
              <h3>Points of interest</h3>
              <AttractionsPointsOfInterest
                pointsOfInterest={values.pointsOfInterest}
                name="pointsOfInterest"
                error={errors.pointsOfInterest}
                touched={touched.pointsOfInterest}
                onChange={setFieldValue}
              />
            </div>
          </div>
          <div className={styles.aside}>
            <div className={styles.box}>
              <h3>Primary image</h3>
              <ImageUploader
                maxCount={1}
                value={values.primaryPicture ? [values.primaryPicture] : []}
                error={errors.primaryPicture}
                touched={touched.primaryPicture}
                onChange={onPrimaryImageChange}
              />
            </div>

            <div className={styles.box}>
              <h3>Details</h3>
              <h4>User generated content</h4>
              <Checkbox
                name="isUGC"
                checked={values.isUGC}
                onChange={handleChange}
              >
                Is UGC
              </Checkbox>

              <h4>Website</h4>
              <Input
                name="website"
                value={values.website}
                error={errors.website}
                touched={touched.website}
                inputStyle="editor"
                onChange={handleChange}
                // placeholder={lang.ATTRACTION_NAME}
              />

              <h4>Phone</h4>
              <Input
                name="phone"
                value={values.phone}
                error={errors.phone}
                touched={touched.phone}
                inputStyle="editor"
                onChange={handleChange}
                // placeholder={lang.ATTRACTION_NAME}
              />
              <h4>Rating</h4>
              <Input
                name="rating"
                value={values.rating}
                error={errors.rating}
                touched={touched.rating}
                inputStyle="editor"
                onChange={handleChange}
                // placeholder={lang.ATTRACTION_NAME}
              />
            </div>

            <div className={classnames(styles.box, styles.actions)}>
              <h3>Actions</h3>

              {renderAttractionInfo()}

              {renderActions()}
            </div>
          </div>
        </div>
      </form>
    );
  }
);

AttractionEditorPageComponent.defaultProps = {
  lang: Lang.ATTRACTIONS_EDITOR_PAGE,
  id: "",
  attraction: {},
  isNew: false,
  createNewAttraction: () => {},
  fetchAttractionById: () => {},
  updateAttraction: () => {},
  publishAttraction: () => {},
};

export { AttractionEditorPageComponent };
