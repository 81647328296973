import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14.832 20.17"
      {...props}
    >
      <g data-name="Group 63">
        <path
          data-name="Path 1373"
          d="M14.314 5.649l-1.055-1.888q-.113-.182-.24-.359c-.041-.058-.085-.116-.129-.172s-.087-.113-.133-.169l-.1-.124a5.302 5.302 0 00-.325-.355l-.076-.076c-.025-.024-.056-.057-.086-.085-.048-.046-.1-.091-.148-.136l-.078-.068-.066-.057c-.043-.037-.086-.073-.13-.107s-.078-.064-.118-.094a5.37 5.37 0 00-.182-.136l-.07-.051-.071-.049-.066-.045-.149-.1a5.535 5.535 0 00-.332-.197c-.082-.046-.166-.089-.25-.131s-.169-.084-.255-.122c-.043-.02-.086-.04-.13-.058A4.655 4.655 0 009.7.906L9.593.869a6.48 6.48 0 00-.411-.124L9.069.716a2.939 2.939 0 00-.168-.039L8.764.648a4.08 4.08 0 00-.187-.034 4.318 4.318 0 00-.254-.038q-.1-.014-.2-.025L7.982.538 7.905.532a5.816 5.816 0 00-.292-.014H7.42A.26.26 0 017.164.3a6.549 6.549 0 00-3.512 1.055A7.559 7.559 0 00.779 4.687l-.01.022h.017a.26.26 0 01.154.334 6.267 6.267 0 00-.121.367l-.04.138c-.013.046-.026.092-.037.139l-.029.117q-.031.127-.055.253c-.02.1-.037.2-.052.3l-.015.1v.033c-.007.047-.013.1-.018.144l-.018.175c-.005.058-.009.1-.012.156l-.008.14c-.002.048 0 .089 0 .133V7.557c0 .048.006.1.009.146s.008.1.015.15c.012.1.027.2.045.3 0 .024.008.049.014.073.008.045.017.09.028.136l.036.157c.013.057.026.107.041.162l.043.156c.015.056.033.118.052.178l.029.093.041.129q.041.13.09.263v.013l.926 3.273 2.812 3.675c.033.053.069.105.1.157s.067.106.1.156l.1.153.2.3c.362.542.7 1.039 1 1.468l.175.25.193.273a1.018 1.018 0 001.659 0l.271-.384.2-.282.1-.148c.033-.048.071-.1.105-.152l.193-.282c.253-.369.523-.768.8-1.192l.16-.244.01-.015q.237-.364.479-.745l.022-.033q.125-.2.253-.4c.411-.659.824-1.346 1.212-2.036q.2-.346.38-.689l.114-.212.129-.246q.192-.368.369-.73l1.464-3.227zM3.888 7.368a3.527 3.527 0 113.527 3.527 3.527 3.527 0 01-3.527-3.527z"
          fill="#fa7448"
        />
        <path
          data-name="Path 1217"
          d="M7.416 0a.26.26 0 100 .52 6.9 6.9 0 016.9 6.9c0 2.96-4.242 9.242-6.066 11.806a1.018 1.018 0 01-1.659 0C4.767 16.663.525 10.38.525 7.42a6.869 6.869 0 01.416-2.365.26.26 0 00-.489-.178A7.391 7.391 0 00.004 7.42c0 3 3.861 8.873 6.163 12.107a1.539 1.539 0 002.507 0c2.3-3.235 6.162-9.109 6.162-12.107A7.425 7.425 0 007.416 0z"
          fill="#78232f"
        />
        <path
          data-name="Path 1218"
          d="M7.416 11.044A3.676 3.676 0 103.74 7.368a3.68 3.68 0 003.676 3.676zm0-6.832a3.155 3.155 0 11-3.155 3.155 3.159 3.159 0 013.155-3.154z"
          fill="#78232f"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
