import { GET, POST } from "../utils/Executer";
import API from "./API";
import { ATTRACTIONS_SERVICE_URL as servicePath, baseURL } from "../constants";

class AttractionsServiceAPI {
  async fetchAttractions() {
    return await API.client.execute(
      GET,
      `${servicePath}?showPublishOnly=false`
    );
  }

  async fetchAttractionById({ id }) {
    return await API.client.execute(
      GET,
      `${servicePath}/${id}?showPublishOnly=false`
    );
  }

  async createNewAttraction({ values }) {
    return await API.client.execute(POST, `${servicePath}`, values);
  }

  async updateAttraction({ id, values }) {
    return await API.client.execute(POST, `${servicePath}/${id}`, values);
  }

  async publishAttraction({ id }) {
    return await API.client.execute(POST, `${servicePath}/${id}/publish`);
  }

  uploadUrl() {
    return `${baseURL}${servicePath}/upload-asset?jwt=${API.client.accessToken}`;
  }
}

export default new AttractionsServiceAPI();
