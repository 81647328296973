import { APPLICATION_STEPS } from "../../constants";
import update from 'immutability-helper';
import _ from 'lodash';
import { APP_FINISH_STEP, APP_IS_READY } from "../actions";

const initialState = {
  isReady: false,
  adminMode: false,
  parts: {
    [APPLICATION_STEPS.PERSIST_STORAGE]: false,
    [APPLICATION_STEPS.AUTHENTICATION_PROCESS]: false,
  }
};

const finishStep = (state, { step }) => {
  if (_.isUndefined(APPLICATION_STEPS[step])) {
    throw new Error(`[App Reducer] non exist step - ${step}`);
  }

  return update(state, {
    parts: {
      [step]: { $set: true }
    }
  })
}

const setAppReady = (state) => update(state, {
  isReady: { $set: true }
})

const actionsRepo = {
  [APP_FINISH_STEP]: finishStep,
  [APP_IS_READY]: setAppReady,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload)
  }

  return state;
};
