import { LOAD_STORAGE, UPDATE_STORAGE } from '../actions';
import { applyInitialState } from '../actions/storage.actions';
import { finishStep } from '../actions/application.actions';
import { STORAGE_KEYS, APPLICATION_STEPS, STORAGE_REDUCERS_TO_SAVE } from "../../constants";
import localStorageHelper from '../../helpers/localstorage.helper';

export const loadState = ({ dispatch }) => next => async action => {
  next(action)

  if (action.type === LOAD_STORAGE) {
    let state = await localStorageHelper.get(STORAGE_KEYS.APPLICATION_DATA) || {};

    dispatch(applyInitialState(state))

    dispatch(finishStep({ step: APPLICATION_STEPS.PERSIST_STORAGE }))
  }
}

export const updatePersistData = ({ getState }) => next => async action => {
  next(action)

  if (action.type === UPDATE_STORAGE) {
    let state = getState()
    let updatedData = {};

    // Map the reducers to the state
    STORAGE_REDUCERS_TO_SAVE.forEach(reducer => updatedData[reducer] = state[reducer]);

    localStorageHelper.set(STORAGE_KEYS.APPLICATION_DATA, updatedData)
  }
}


export const storageMiddleware = [loadState, updatePersistData]